<template>
  <div class="pinyin-summary-container layout-border">
    <PageButton :bottomCornerButtonList="bottomCornerButtonList" :isLastStep="isLastStep" @clickNext="nextPage" @updateNumbers="updateNumbers($event)"></PageButton>
    <div class="pinyin-summary-content">
      <div class="check-area">
        <img :src="bgImg" alt="" />
      <template v-for="(item, index) in pinyinImgList" >
        <img class="pinyin-image" :src="item" alt="" v-if="pinyinIndexArr.indexOf(index) > -1" :key="index + 'pinyin'"/>
      </template>
        <div v-show="lesson !== 15 && lesson !== 47 && lesson !== 63" class="check-item" :class="'toneArea-' + index" v-for="(item, index) in 4" :key="index + 'check'" @click="handleClickTone(index)"></div>
        <div :class="'check-item-pinyin-' + lesson">
          
          <div class="check-item" :class="'pinyinArea-' + index" v-for="(item, index) in pinyinImgList.length" :key="index + 'check1'" @click="handleClickPinyin(index)"></div>
        </div>
      </div>
      <!-- <div class="pinyin-area" v-for="(item, index) in pinyinImgList" :key="index + 'pinyin'">
        <img :src="item" alt="" v-if="pinyinIndexArr.indexOf(index) > -1" />
      </div> -->
      <div class="tone-area" v-for="(item, index) in toneImgList" :key="index + 'tone'">
        <img :src="item" alt="" v-if="toneIndexArr.indexOf(index) > -1" />
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    pinyinImgList: {
      type: Array,
      require: true,
    },
    bgImg: {
      type: String,
      require: true,
    },
    lesson: {
      type: Number,
      require: true,
    },
  },

  computed: {
    toneImgList() {
      let imgArr = [];
      for (let i = 1; i <= 4; i++) {
        const element = require(`@/assets/img//12-Review/lesson-07-review-tone${i}.svg`);
        imgArr.push(element);
      }
      return imgArr;
    },
  },
  watch: {
    step(value) {
      if (value === this.pinyinImgList.length&&this.toneIndexArr.length===4) {
        this.isLastStep = true;
      }
    },
    pinyinSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002301,
          data: { value },
          text: "pinyinSummary点击pinyin socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    toneSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002302,
          data: { value },
          text: "pinyinSummary点击tone socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  data() {
    return {
      pinyinSocketInfo: {},
      toneSocketInfo: {},
      pinyinIndexArr: [],
      toneIndexArr: [],
      step: 0,
      les: this.lesson,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on("clickPinyinItem", ({ index }) => {
      this.handleClickPinyin(index, true);
    });
    this.$bus.$on("clickToneItem", ({ index }) => {
      this.handleClickTone(index, true);
    });
  },
  methods: {
    handleClickTone(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.toneSocketInfo = {
          index,
          value: Math.random(),
        };
        this.handleClickToneValue;
      }

      console.log(index);
      this.toneIndexArr.push(index);
      if (this.toneIndexArr.length===4&&this.pinyinIndexArr.length===this.pinyinImgList.length) {
        this.isLastStep = true;
        return
      }
     
    },
    handleClickPinyin(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.pinyinSocketInfo = {
          index,
          value: Math.random(),
        };
        console.log(index);
      }
      this.pinyinIndexArr.push(index);
      this.step++;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    beforeDestroy() {
      this.$bus.$off("clickPinyinItem");
      this.$bus.$off("clickToneItem");
    },
  },
};
</script>
<style scoped lang="scss">
.pinyin-summary-container {
  background-color: #f7f4e0;
  .pinyin-summary-content {
    position: relative;
    width: fit-content;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: auto;
    .bg-img {
      width: 100%;
      height: 100%;

      img {
        border-radius: 58px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
    .pinyin-area,
    .tone-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
      pointer-events: none;
      //   object-fit: fill;
      img {
        border-radius: 58px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .check-area {
      position: relative;
      height: 100%;
      z-index: 20;
      .pinyin-image {
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        position: relative;
        max-width: 100%;
        height: 100%;
        text-align: -webkit-center;
        overflow: hidden;
      }
      .check-item {
        position: absolute;
        // background: #000;
        // opacity: 0.5;
        top: 76%;
        left: 20%;
        cursor: pointer;
      }

      .toneArea-0 {
        width: 53px;
        height: 24px;
        left: 26%;
        top: 71%
        // background-color: blue;
      }
      .toneArea-1 {
        width: 36px;
        height: 25px;
        // background-color: blue;
        left: 37%;
        top: 71%
      }
      .toneArea-2 {
        width: 49px;
        height: 28px;
        // background-color: blue;
        left: 55%;
        top: 71%
      }
      .toneArea-3 {
        width: 36px;
        height: 27px;
        // background-color: blue;
        left: 65%;
        top: 71%
      }
       .check-item-pinyin-7 {
        .pinyinArea-0 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 24%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 32%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 39%;
        }
        .pinyinArea-3 {
          width: 32px;
          height: 72px;
          top: 50%;
          left: 54%;
        }
        .pinyinArea-4 {
          width: 53px;
          height: 58px;
          top: 52%;
          left: 60%;
        }
        .pinyinArea-5 {
          width: 53px;
          height: 68px;
          top: 50%;
          left: 67%;
        }
      }
      .check-item-pinyin-15 {
        .pinyinArea-0 {
          width: 53px;
          height: 58px;
          top: 60%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 58px;
          top: 63%;
          left: 37%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 60%;
          left: 54%;
        }
        .pinyinArea-3 {
          width: 32px;
          height: 62px;
          top: 59%;
          left: 64%;
        }
      }
      .check-item-pinyin-23 {
        .pinyinArea-0 {
          width: 58px;
          height: 76px;
          top: 60%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 53px;
          height: 68px;
          top: 60%;
          left: 37%;
        }
        .pinyinArea-2 {
          width: 53px;
          height: 58px;
          top: 63%;
          left: 56%;
        }
        .pinyinArea-3 {
          width: 28px;
          height: 76px;
          top: 59%;
          left: 66.5%;
        }
      }
      .check-item-pinyin-31 {
        .pinyinArea-0 {
          width: 6%;
          height: 14%;
          top: 65%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 5%;
          height: 11%;
          top: 63%;
          left: 34%;
        }
        .pinyinArea-2 {
          width: 5%;
          height: 11%;
          top: 63%;
          left: 41%;
        }
        .pinyinArea-3 {
          width: 4%;
          height: 14%;
          top: 62%;
          left: 56%;
        }
         .pinyinArea-4 {
          width: 5%;
          height: 11%;
          top: 65%;
          left: 61%;
        }
         .pinyinArea-5 {
          width: 5%;
          height: 8%;
          top: 65%;
          left: 67%;
        }
      }
      .check-item-pinyin-39 {
        .pinyinArea-0 {
          width: 5%;
          height:10%;
          top: 57%;
          left: 27%;
        }
        .pinyinArea-1 {
          width: 5%;
          height:10%;;
          top: 57%;
          left: 38%;
        }
        .pinyinArea-2 {
          width: 5%;
          height:10%;;
          top: 57%;
          left: 56%;
        }
        .pinyinArea-3 {
          width: 10%;
          height:10%;;
          top: 70%;
          left: 24%;
        }
        .pinyinArea-4 {
          width: 10%;
          height:10%;;
          top: 70%;
          left: 35.5%;
        }
        .pinyinArea-5 {
          width: 10%;
          height:10%;;
          top: 70%;
          left: 54%;
        }
        .pinyinArea-6 {
          width: 5%;
          height:10%;;
          top: 70%;
          left: 68%;
        }
      }
       .check-item-pinyin-47 {
        .pinyinArea-0 {
          width: 7%;
          height:8%;
          top: 50%;
          left: 26%;
        }
        .pinyinArea-1 {
          width: 7%;
          height:8%;;
          top: 50%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 9%;
          height:8%;;
          top: 50%;
          left: 58%;
        }
        .pinyinArea-3 {
          width: 9%;
          height: 8%;
          top: 63%;
          left: 36%;
        }
        .pinyinArea-4 {
          width: 7%;
          height:8%;;
          top: 63%;
          left: 25%;
        }
        .pinyinArea-5 {
          width: 8%;
          height: 8%;
          top: 74%;
          left: 25%;
        }
        .pinyinArea-6 {
          width: 7%;
          height:8%;;
          top: 63%;
          left: 58%;
        }
         .pinyinArea-7 {
          width: 7%;
          height: 8%;
          top: 75%;
          left: 36%;
        }
        .pinyinArea-8 {
          width: 7%;
          height:8%;;
          top: 75%;
          left: 58%;
        }
      }
       .check-item-pinyin-55 {
        .pinyinArea-0 {
          width: 8%;
          height:8%;
          top: 50%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 9%;
          height:8%;;
          top: 50%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 9%;
          height:8%;;
          top: 50%;
          left: 52%;
        }
        .pinyinArea-3 {
          width: 9%;
          height:8%;;
          top: 50%;
          left: 64%;
        }
        .pinyinArea-4 {
          width: 13%;
          height:8%;;
          top: 63%;
          left: 27%;
        }
        .pinyinArea-5 {
          width: 12%;
          height: 8%;
          top: 75%;
          left: 37%;
        }
        .pinyinArea-6 {
          width: 12%;
          height:8%;;
          top: 63%;
          left: 58%;
        }
         .pinyinArea-7 {
          width: 14%;
          height:8%;;
          top: 75%;
          left: 27%;
        }
        .pinyinArea-8 {
          width: 14%;
          height:8%;;
          top: 75%;
          left: 57%;
        }
      }
       .check-item-pinyin-63 {
        
        .pinyinArea-0 {
          width: 8%;
          height:8%;
          top: 48%;
          left: 25%;
        }
        .pinyinArea-1 {
          width: 9%;
          height:8%;;
          top: 48%;
          left: 36%;
        }
        .pinyinArea-2 {
          width: 9%;
          height:8%;;
          top: 48%;
          left: 52%;
        }
        .pinyinArea-3 {
          width: 11%;
          height: 8%;
          top: 79%;
          left: 64%;
        }
        .pinyinArea-4 {
          width: 5%;
          height:8%;;
          top: 48%;
          left: 63%;
        }
        .pinyinArea-5 {
          width: 11%;
          height: 8%;
          top: 78%;
          left: 31%;
        }
        .pinyinArea-6 {
          width: 8%;
          height:8%;;
          top: 58%;
          left: 25%;
        }
         .pinyinArea-7 {
          width: 8%;
          height:8%;;
          top: 58%;
          left: 36%;
        }
        .pinyinArea-8 {
          width: 7%;
          height:8%;;
          top: 58%;
          left: 53%;
        }
        .pinyinArea-9 {
          width: 10%;
          height:8%;;
          top: 58%;
          left: 62%;
        }
        .pinyinArea-10 {
          width: 10%;
          height: 8%;
          top: 68%;
          left: 25%;
        }
        .pinyinArea-11 {
          width: 6%;
          height:8%;;
          top: 68%;
          left: 53%;
        }
        .pinyinArea-12 {
          width: 9%;
          height:8%;;
          top: 68%;
          left: 63%;
        }
        .pinyinArea-13 {
          width: 8%;
          height: 8%;
          top: 78%;
          left: 23%;
        }
         .pinyinArea-14 {
          width: 13%;
          height: 8%;
          top: 79%;
          left: 51%;
        }
         .pinyinArea-15 {
          width: 10%;
          height: 8%;
          top: 68%;
          left: 34%;
        }
      }
      .check-item-pinyin-7 {
        .check-item {
          top: 52%;
          width: 5%;
          height: 40px;
        }

        .pinyinArea-0 {
          left: 23%;
        }
        .pinyinArea-1 {
          left: 32%;
        }

        .pinyinArea-2 {
          left: 40%;
        }
        .pinyinArea-3 {
          left: 52%;
        }
        .pinyinArea-4 {
          left: 60%;
        }
        .pinyinArea-5 {
          left: 68%;
        }
        .pinyinArea-6 {
        }
      }
    }
  }
}

::v-deep .pageBottomCornerButton {
  z-index: 99 !important;
}
</style>
